import React from "react"
import MediaImage from "./media-image"
import MediaVideo from "./media-video"

const MediaGallery = ({ media }) => {
  const {
    _type,
    asset,
    title,
    url,
    urlLowDef,
    description,
    videoOption,
  } = media

  switch (_type) {
    case "mainImage":
      return (
        <MediaImage src={asset.fluid} title={title} description={description} />
      )
    case "video":
      return (
        <MediaVideo
          src={url}
          srcLowDef={urlLowDef}
          description={description}
          option={videoOption}
        />
      )
    default:
      return null
  }
}

export default MediaGallery
