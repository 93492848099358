import React from "react"
import Img from "gatsby-image"

const MediaImage = ({ src, title, description }) => (
  <div className="relative w-full h-full">
    <Img
      fluid={src}
      className="w-full h-full"
      imgStyle={{ objectFit: "contain" }}
      alt={title}
    />
    {description && (
      <span className="absolute inset-x-0 bottom-0 -mb-12 font-sans text-sm text-center uppercase truncate">
        {description}
      </span>
    )}
  </div>
)

export default MediaImage
