import React, { useState, useRef, useCallback, useEffect } from "react"
import ReactPlayer from "react-player"
import screenfull from "screenfull"
import { findDOMNode } from "react-dom"
import { useMedia } from "react-use"
import { useInView } from "react-intersection-observer"
import {
  MdPlayArrow,
  MdPause,
  MdVolumeUp,
  MdVolumeOff,
  MdFullscreen,
} from "react-icons/md"
import { motion } from "framer-motion"
import { pulse } from "../components/motion"

const MediaVideo = ({ src, urlLowDef, description, option }) => {
  const hasDefaultOption = option ?? "default"
  const hasDefaultUrl = urlLowDef ?? src

  const [canPlay, setCanPlay] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [muted, setMuted] = useState(true)
  const [played, setPlayed] = useState(0)
  const [seeking, setSeeking] = useState(false)
  const [intersectionRef, inView] = useInView({
    root: null,
    rootMargin: "20px",
    threshold: 0.5,
  })
  const isWide = useMedia("(min-width: 960px)")
  const videoUrl = isWide ? src : hasDefaultUrl

  const playerRef = useRef(null)

  const handlePlayPause = () => {
    setPlaying(!playing)
  }

  const handlePlay = () => {
    setPlaying(true)
  }

  const handlePause = () => {
    setPlaying(false)
  }

  const handleSeekChange = e => {
    setPlayed(parseFloat(e.target.value))
  }

  const handleSeekDown = () => {
    setSeeking(true)
  }

  const handleSeekUp = e => {
    setSeeking(false)
    playerRef.current.seekTo(parseFloat(e.target.value))
  }

  const handleProgress = e => {
    if (!seeking) return setPlayed(parseFloat(e.played))
  }

  const handleToggleMuted = () => {
    setMuted(!muted)
  }

  const handleFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.request(findDOMNode(playerRef.current))
    }

    setPlaying(true)
    setMuted(false)
  }

  const handleCanPlay = () => {
    setCanPlay(true)
  }

  const handleInView = useCallback(() => {
    if (!inView) return setPlaying(false)
    setPlaying(true)
  }, [inView, setPlaying])

  useEffect(() => {
    handleInView()
  }, [handleInView])

  return (
    <>
      <div className="relative w-full h-full" ref={intersectionRef}>
        {!canPlay && (
          <motion.span
            className="text-base text-center uppercase md:text-xl abs-c"
            {...pulse}
          >
            Loading video
          </motion.span>
        )}
        {hasDefaultOption === "default" ? (
          <ReactPlayer
            ref={playerRef}
            url={videoUrl}
            width="100%"
            height="100%"
            playing={playing}
            muted={muted}
            loop={true}
            playsinline={true}
            onPlay={handlePlay}
            onPause={handlePause}
            onProgress={handleProgress}
            onReady={handleCanPlay}
          />
        ) : (
          <ReactPlayer
            url={videoUrl}
            width="100%"
            height="100%"
            playing={true}
            muted={true}
            loop={true}
            playsinline={true}
            onReady={handleCanPlay}
          />
        )}
        {hasDefaultOption === "default" && (
          <div
            aria-label="controls"
            className="absolute inset-x-0 bottom-0 flex items-center justify-center w-full max-w-6xl px-5 mx-auto -mb-10 space-x-2 md:space-x-6"
          >
            <button
              aria-label={playing ? "Pause" : "Play"}
              onClick={handlePlayPause}
            >
              {playing ? <MdPause size="24" /> : <MdPlayArrow size="24" />}
            </button>
            <div className="w-full">
              <input
                type="range"
                aria-label="Seek"
                min={0}
                max={0.999999}
                step="any"
                value={played}
                onMouseDown={handleSeekDown}
                onChange={handleSeekChange}
                onMouseUp={handleSeekUp}
                onTouchStart={handleSeekDown}
                onTouchEnd={handleSeekUp}
              />
            </div>
            <button onClick={handleFullscreen} aria-label="Fullscreen">
              <MdFullscreen size="24" />
            </button>
            <button
              onClick={handleToggleMuted}
              aria-label={muted ? "UnMute" : "Mute"}
            >
              {muted ? (
                <motion.div {...pulse}>
                  <MdVolumeOff size="24" />
                </motion.div>
              ) : (
                <div>
                  <MdVolumeUp size="24" />
                </div>
              )}
            </button>
          </div>
        )}
        {description && (
          <span className="absolute inset-x-0 bottom-0 -mb-12 font-sans text-sm text-center uppercase truncate">
            {description}
          </span>
        )}
      </div>
    </>
  )
}

export default MediaVideo
