import React from "react"
import Layout from "../components/layout"
import Project from "../components/project"
import SEO from "../components/seo"
import useSite from "../hooks/use-site"
import { toPlainText } from "../lib/helpers"

export const query = graphql`
  query ProjectTemplate($_id: String!) {
    project: sanityProject(_id: { eq: $_id }) {
      title
      coverImage {
        asset {
          _id
        }
      }
      _rawDetails(resolveReferences: { maxDepth: 10 })
      gallery {
        ... on SanityMainImage {
          _key
          _type
          description
          asset {
            fluid(maxWidth: 1920) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
        ... on SanityVideo {
          _key
          _type
          url
          urlLowDef
          description
          videoOption
          posterImage {
            asset {
              url
            }
          }
        }
      }
    }
  }
`

const ProjectTemplate = ({ data, pageContext }) => {
  const project = data && data.project
  const site = useSite()

  return (
    <Layout>
      <SEO
        title={`${site.title} — ${project.title || ""}`}
        description={toPlainText(project._rawDetails)}
        url={site.url}
        image={project.coverImage}
      />
      <Project content={project} context={pageContext} />
    </Layout>
  )
}

export default ProjectTemplate
