import React, { useState, useEffect, useCallback } from "react"
import { Link, navigate } from "gatsby"
import { useToggle, useMedia } from "react-use"
import { motion, AnimatePresence } from "framer-motion"
import { useEmblaCarousel } from "embla-carousel/react"
import { MdRemove, MdAdd } from "react-icons/md"
import cx from "clsx"
import { fade } from "../components/motion"
import PortableText from "./portable-text"
import MediaGallery from "./media-gallery"

const Project = ({ content, context }) => {
  const { title, _rawDetails, gallery } = content
  const { next, firstProject } = context

  const isWide = useMedia("(min-width: 1024px)")
  const [emblaRef, embla] = useEmblaCarousel({
    draggable: isWide ? false : true,
  })
  const [on, toggle] = useToggle(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])

  const isFirst = selectedIndex === 0

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

  const onSettle = useCallback(() => {
    if (!embla) return
    if (!embla.canScrollNext() && next !== null) {
      navigate(`/${next.slug.current}`)
    }
    if (!embla.canScrollNext() && next === null) {
      navigate(`/${firstProject.slug.current}`)
    }
  }, [embla, next, firstProject])

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
  }, [embla, setSelectedIndex])

  const onScroll = useCallback(() => {
    if (!embla) return
    toggle(false)
  }, [embla, toggle])

  useEffect(() => {
    if (!embla) return
    onSelect()
    onSettle()
    onScroll()
    setScrollSnaps(embla.scrollSnapList())
    embla.on("select", onSelect)
    embla.on("settle", onSettle)
    embla.on("scroll", onScroll)
  }, [embla, onSelect, onSettle, onScroll])

  return (
    <div className="relative">
      <div
        ref={emblaRef}
        className={cx(
          "slider transition-opacity duration-200",
          on && "opacity-15"
        )}
      >
        <div className="slider-container">
          <>
            {gallery &&
              gallery.map(media => (
                <div key={media._key} className="slider-slide">
                  <div className="h-screen py-24 slider--slide-inner">
                    <MediaGallery media={media} />
                  </div>
                </div>
              ))}
            <div className="relative flex items-center justify-center h-screen min-w-full pl-5 select-none">
              <div
                aria-label="Scroll to next project"
                className="px-4 leading-none text-center"
              >
                <span className="max-w-xl mx-auto font-serif text-2xl md:text-6xl">
                  {next ? next.title : firstProject.title}
                </span>
              </div>
            </div>
          </>
        </div>
        <button
          onClick={isFirst ? scrollNext : scrollPrev}
          aria-label={isFirst ? "Scroll next" : "Scroll previous"}
          className={cx(
            "hidden lg:block slider-button button-prev",
            isFirst ? "cursor-next" : "cursor-prev"
          )}
        />
        <button
          onClick={scrollNext}
          aria-label="Scroll next"
          className="hidden lg:block slider-button button-next cursor-next"
        />
      </div>

      {on && (
        <div className="z-10 w-full max-w-sm px-6 space-y-4 text-xs text-center uppercase md:max-w-lg md:text-base abs-c">
          {_rawDetails && <PortableText blocks={_rawDetails} />}
        </div>
      )}

      <AnimatePresence>
        {embla && embla.canScrollNext() && (
          <motion.div
            {...fade}
            className="absolute inset-x-0 bottom-0 z-50 flex items-center justify-between px-4 pb-5 md:px-6"
          >
            <div className="w-32">
              <Link to="/" className="inline-flex items-baseline uppercase">
                <span className="w-2 h-2 mt-px mr-3 bg-black rounded-full" />
                Index
              </Link>
            </div>
            <h3 className="w-full px-2 font-serif text-xl text-center truncate select-none">
              {title}
            </h3>
            <div className="flex items-center justify-end w-32 space-x-2">
              <span className="text-center uppercase select-none">
                {selectedIndex + 1}/{scrollSnaps.length - 1}
              </span>
              <button className="flex items-center uppercase" onClick={toggle}>
                <span className="hidden sm:block md:mr-2">Details</span>
                <span className="mt-px">{on ? <MdRemove /> : <MdAdd />}</span>
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Project
